<template>
  <a
    class="frn_button"
    :class="[light ? 'light' : '']"
    v-bind:href="href"
    v-bind:target="target"
    ><i :class="icon"></i
  ></a>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    href: String,
    target: String,
    light: String,
    icon: String,
  },

  computed: {},
  methods: {},
};
</script>
<style scoped lang="scss">
@import "../../scss/colors";

#frn-loginbox {
  a {
    text-decoration: none;
    font-size: 1em;
    color: $frn-white;
  }

  .light {
    background: #828999;
  }

  .light:hover {
    background: #4e5a75;
  }

  .frn_button:link,
  .frn_button:visited,
  .frn_button:link,
  .frn_button:visited {
    color: $frn-white;
    text-decoration: none;
  }

  .frn_subPage {
    display: flex;
    justify-content: flex-end;
    padding: 0 6px 0;

    .frn_button {
      font-size: 0.8rem;
      padding: 3px 9px;
      white-space: nowrap;
      background-color: $frn-green;

      &:last-of-type {
        margin-left: 5px;
        background-color: $frn-dark-blue;
      }
    }
  }
  .icon-only {
    min-width: auto;
    width: 100%;
    height: 20px;
    &::after {
      content: "";
      position: relative;
      display: inline-block;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
    }
  }

  .icon-help-passkey {
    width: 20px;

    &::after {
      width: 20px;
      height: 20px;
      background: url("https://components.freenet.de/assets/Login/icon-question-circle-filled.svg")
        no-repeat;
    }
  }

  .frn-white {
    .icon-help-passkey {
      &::after {
        background: url("https://components.freenet.de/assets/Login/icon-question-circle-filled_blue.svg")
        no-repeat;
      }
    }
  }
}
</style>
